import {createStore} from 'vuex'

export const store = createStore({
  state: {
    isLoaded : true
  },
  getters:{
    getIsLoaded (state) {
      return state.isLoaded
    }
  },
  setters:{
    setIsLoaded (state) {

    }
  }
});