<template>
  <h3 id="headerText">Ваш Telegram ID отсутствует в базе приложения. Пройдите процедуру регистрации.</h3>
  <form @submit.prevent="submit">
    <v-text-field
      v-model="name"
      :counter="10"
      :error-messages="v$.name.$errors.map(e => e.$message)"
      label="ФИО"
    ></v-text-field>

    <v-text-field
      v-model="phone"
      :counter="7"
      :error-messages="v$.phone.$errors.map(e => e.$message)"
      label="Номер телефона"
    ></v-text-field>

    <v-checkbox
      v-model="checkbox"
      :error-messages="v$.checkbox.$errors.map(e => e.$message)"
      label=""
      type="checkbox"
      value="1"
    > 
    <template v-slot:label>
      <div>
        Я согласен(на) на обработку персональных данных и ознакомлен(а) с
        <a id="offerLink" @click.prevent="setRoute('public_offer')">
          офертой
        </a>
      </div>
    </template>
      <!-- <template v-slot:label>
        <div>
          Я согласен(на) на обработку персональных данных и ознакомлен(а) с 
          <v-tooltip location="bottom">
            <template v-slot:activator="{ props }">
              <a
                href="https://vuetifyjs.com"
                target="_blank"
                v-bind="props"
                @click.stop
              >
              офертой
              </a>
            </template>
            Opens in new window
          </v-tooltip>
        </div>
      </template> -->
    </v-checkbox>

    <v-btn class="me-4" type="submit">Зарегистрироваться</v-btn>

    <v-btn @click="handleReset">Очистить</v-btn>
  </form>
</template>

<script>
import axios from 'axios';
import useVuelidate from '@vuelidate/core';
import { required, minLength, helpers } from '@vuelidate/validators';
import router from "@/routes";
import module_setup from '@/assets/module_status.json';

export default {
  name: "RegisterPage",
  data() {
    return {
      name: '',
      phone: '',
      telegram_id: window.Telegram.WebApp.initDataUnsafe.user.id,
      checkbox: false,
      modules_status: module_setup,
      add_path: process.env.VUE_APP_ROUTER_ADD_PATH,
      v$: useVuelidate() // Initialize v$ here
    }
  },
  validations() {
    return {
      name: { 
        required: helpers.withMessage('Необходимо заполнить ФИО', required), 
        minLength: helpers.withMessage('Минимальная длина ФИО - 2 символа', minLength(2)) 
      },
      phone: { 
        required: helpers.withMessage('Необходимо заполнить номер телефона', required), 
        minLength: helpers.withMessage('Минимальная длина номера телефона - 7 символов', minLength(7)),
        regex: helpers.withMessage('Номер телефона должен содержать только цифры и дефисы', helpers.regex(/[0-9-]+/))
      },
      checkbox: { 
        required: helpers.withMessage('Необходимо согласиться с Офертой для регистрации', value => value === '1') 
      },
    };
  },
  methods: {
    async submit() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      const dataValues = JSON.stringify({
          fio: this.name,
          phone: this.phone,
          telegram_id: this.telegram_id.toString(),
          company: 'some company name'
        });
      console.log('typeOf ' + typeof dataValues);
      const dataToSend = {
        data: dataValues,
        initData: window.Telegram.WebApp.initData
      };

      try {
        const response = await axios.post(
          process.env.VUE_APP_WEBSITE_ADDRESS +
          process.env.VUE_APP_WEBSITE_PREFIX +
          process.env.VUE_APP_API_URL + '/register_user',
          dataToSend,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            },
          }
        );
        console.log('response.data');
        console.log(response.data);
      } catch (error) {
        console.error('There was a problem with the axios operation:', error);
        console.error('Data sent:', dataToSend);
        console.error('Error response:', error.response);
      };
      this.setRoute(this.add_path + '/approvement');
    },
    setRoute(path) {
      router.push({ path: path });
    },
    handleReset() {
      this.name = '';
      this.phone = '';
      this.checkbox = false;
      this.v$.$reset();
    },
  },
  mounted() {
    console.log('initDataUnsafe ');
    console.log(window.Telegram.WebApp.initDataUnsafe);
  },
}
</script>

<style>
/* #headerText {
  background-color: rgb(240, 240, 233);
} */
  #offerLink{
    color: blue;
    text-decoration: underline;
  }
  .inline-link {
  display: inline;
}
</style>
