<template>
  <v-layout class="rounded rounded-md">
    <v-main id="body">
    <router-view></router-view>
    </v-main>
  </v-layout>
</template>

<script>
import RegisterPage from './pages/RegisterPage.vue';


export default {
  name: 'App',
  components: {

  },
  mounted()
  {
    this.$router.push(process.env.VUE_APP_ROUTER_ADD_PATH+'/');
    if (window.Telegram && window.Telegram.WebApp)
    {
      window.Telegram.WebApp.BackButton.show();
      window.Telegram.WebApp.BackButton.onClick(()=>{
     //   if (this.$router.currentRoute==process.env.VUE_APP_ROUTER_ADD_PATH+'/')
      //  {
      //    window.Telegram.WebApp.close();
       // }
       // else {
       //   this.$router.go(-1);
       // }
        if (window.history.state.back == null ||
            window.history.state.back== window.history.state.current ||
            window.history.state.position == 0) {
          //you cannot go back
          window.Telegram.WebApp.close();
        }
        else
          window.history.back();

      });
    }
  }
}
</script>

<style>
.phone-span
{
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
  font-size: 1rem;
  text-rendering: optimizeLegibility;
  display: inline-block;
  padding: 5px;
  display: inline-block;
  opacity: 1;
  color: rgba(var(--v-theme-info));
}
#body{
  background-color: rgb(240, 240, 233);
}
</style>
