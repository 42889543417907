import {
    createRouter,
    createWebHistory,
    createWebHashHistory
} from 'vue-router'
import Vue from 'vue'
const MenuPage = () => import('@/pages/MenuPage.vue');
const TeamPage = () => import('@/pages/TeamPage.vue');
const TimeTablePage = () => import('@/pages/TimeTablePage.vue');
const ProfilePage = () => import('@/pages/ProfilePage.vue');
const ShopPage = () => import('@/pages/ShopPage.vue');
const ContactPage = () => import('@/pages/ContactPage.vue');
const RegisterPage = () => import('@/pages/RegisterPage.vue');
const PublicOfferPage = () => import('@/pages/PublicOfferPage.vue');
const ApprovementPage = () => import('@/pages/ApprovementPage.vue');
const routerHistory = createWebHistory()
//const routerHistory=createWebHashHistory();
// 1. Define route components.
// These can be imported from other files



const About = { template: '<div>About</div>' }

// 2. Define some routes
// Each route should map to a component.
// We'll talk about nested routes later.
const apath=process.env.VUE_APP_ROUTER_ADD_PATH;
const routes = [
    { path: apath+'/', component: MenuPage },
    { path: apath+'/team' , component: TeamPage },
    { path: apath+'/timetable',component: TimeTablePage},
    { path: apath+'/profile', component: ProfilePage},
    { path: apath+'/shop', component: ShopPage },
    { path: apath+'/contacts', component: ContactPage},
    { path: apath+'/register', component: RegisterPage},
    { path: apath+'/public_offer', component: PublicOfferPage},
    { path: apath+'/approvement', component: ApprovementPage}
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: routerHistory,
    routes, // short for `routes: routes`
})

export default router;
